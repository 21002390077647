import React, {useState, useEffect, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import "./theme/main.scss";
import io from 'socket.io-client';
import Widget from "./components/Widget";
import { useImmer } from "use-immer";

export const App = () => {

  const socketRef = useRef();
  
  const [ref,setRef] = useState(undefined);
  const [remoteStatus, setRemoteStatus] = useState("unavailable");
  const [connecting, setConnecting] = useState(true);
  
  const [messages, setMessages] = useImmer([]);

  useEffect(() => {
    
    const messageHandler = (evt) => {
      const receivedRef = evt.data.ref;
      if ( receivedRef !== null && typeof receivedRef !== "undefined" ) {
        setRef(receivedRef);
      } else {
        setRef("");
      }
    };
    
    window.addEventListener("message", messageHandler);
    
    return () => window.removeEventListener("message", messageHandler);
    
  },[]);
  
  // A to uzależnić od tego, czy init się wykonał
  useEffect(() => {
    
    if ( typeof ref !== "undefined" ) {
      
      let serverAddress = "http://localhost:3000";
      if (process.env.NODE_ENV === 'production') {
        console.log = () => {};
        serverAddress = "https://w-api.chatscope.io";
      }

      serverAddress += `?perseverance=1&ref=${ref}`;

      const socket = io(serverAddress, {
        transports: ['websocket']
      });

      socket.on("connect", () => {
        socketRef.current = socket;
        setMessages(msgs => msgs.concat([{
          message: "Please wait for the <strong>Martian</strong> to connect...",
          sentTime: "now",
          sender: "martian",
          direction: "incoming",
          position: "single"
        },
          {
            message: `Remember that <span class="mars">Mars</span> is approximately 11 light minutes away from Earth.<br />Therefore, the delay in receiving a message from a <strong>Martian</strong> may be more than 20 minutes  (or not :) )`,
            sentTime: "now",
            sender: "martian",
            direction: "incoming",
            position: "single"  
          },
          {
            message: "So please be patient, the Martian will surely answer you",
            sentTime: "now",
            sender: "martian",
            direction: "incoming",
            position: "single"
          }
        ]));
        setConnecting(false);
      });

      socket.on("message", (msg) => {

        let content = msg === "chatscope joined" ? "<strong>Martian</strong> connected" : msg;

        content = content === "chatscope disconnected" ? "<strong>Martian</strong> disconnected" : content;

        content = content.replace(/(Perseverance-\.*\d{1,})/, "<span class=\"user\">$1</span>");
        
        const status = (() => {
          if ( msg === "chatscope joined" ) {
            return "available";
          } else {
            return "unavailable";
          }
        })();
        
        const newMessage = {
          message: content,
          sentTime: "now",
          sender: "martian",
          direction: "incoming",
          position: "single"
        };

        setRemoteStatus(status);
        setMessages(msgs => msgs.concat(newMessage));

      });
      
    }
    
  },[ref]);

  const handleSend = msg => {

    const newMessage = {
      message: msg,
      sentTime: "now",
      sender: "me",
      direction: "outgoing",
      position: "single"
    };

    setMessages( msgs => msgs.concat(newMessage));

    socketRef.current.emit("message", msg);

  };

  return <div className="position-relative h-100 w-100">
    <Widget messages={messages}
            onSend={handleSend}
            connecting={connecting}
            remoteStatus={remoteStatus}
    />
  </div>;

};

export default App;