import React,{useState} from "react";
import PropTypes from "prop-types";
import {MainContainer, ChatContainer, ConversationHeader, Message, MessageList, Avatar, MessageInput} from "@chatscope/chat-ui-kit-react";
import avatar from "../assets/alienAvatar.png";
import noop from "../utils/noop";

export const Widget = ({messages, onSend, connecting, remoteStatus}) => {

  const [message,setMessage] = useState("");

  const handleChange = msg => setMessage(msg);

  const handleSend = value => {
    setMessage("");
    onSend(value);
  };

  return <div className="d-flex flex-column position-absolute mars-widget" style={{top:0,left:0,right:0, bottom:0}}>
  <MainContainer style={{fontSize:"1em"}}>
    <ChatContainer>

      <ConversationHeader>
        <Avatar src={avatar} name="chatscope" status={remoteStatus} />
        <ConversationHeader.Content userName="Martian" info="Welcome to Mars!" />
      </ConversationHeader>

      <MessageList className="chat-message-list">
        {messages.map( (m,i) => <Message key={i} model={m} />) }
      </MessageList>
      
      <MessageInput placeholder="Type message here" attachButton={false} onSend={handleSend}  onChange={handleChange} autoFocus={true}
                    disabled={connecting}
                    value={message}/>
    </ChatContainer>
  </MainContainer>
    <div className="text-center p-1 mars-footer">
      Want to make a cool widget like this one?<br />
      Try our free chat components
      <a href="https://github.com/chatscope/chat-ui-kit-react" target="_blank">@chatscope/chat-ui-kit-react</a>
      <hr className="my-1"/>
      <div className="text-center" style={{fontSize: "0.9em"}}>Powered by <a href="https://chatscope.io" target="_blank">chatscope</a></div>
    </div>
  </div>;
};

Widget.propTypes =  {
  messages: PropTypes.array,
  connecting: PropTypes.bool,
  remoteStatus: PropTypes.string,
  onSend: PropTypes.func,
};

Widget.defaultProps = {
  messages: [],
  connecting: false,
  remoteStatus: "unavailable",
  onSend: noop
};

export default Widget;
